<template>
  <common-modal @close="$emit('close')">
    <div class="max-h-96">
      <h1 class="text-xl font-semibold">Send Message</h1>
      <div class="flex flex-col mb-6 mt-8">
        <div>
          <p class="text-grey-500 mb-2 text-sm">To:</p>
          <div
            class="h-36 p-2 border border-grey-200 rounded-lg flex flex-wrap overflow-auto"
            @click="targetInput($event)"
          >
            <span
              class="bg-blue-100 h-12 p-4 my-2 mx-1 flex text-sm items-center rounded-lg text-blue"
              v-for="(ambassador, index) of ambassadorsToMessage"
              :key="`ambassador-to-message-${index}`"
            >
              {{ ambassador.firstName }} {{ ambassador.lastName }}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-3 w-3 ml-2 hover:text-blue-500 transform hover:scale-125 ease"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                @click="removeAmbassador(ambassador)"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="4"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </span>
            <input
              class="h-12 m-2 flex items-center focus:outline-none relative"
              v-model="input"
              @keyup="searchAmbassadors"
            />
          </div>
        </div>
        <div class="relative">
          <div
            class="absolute top-1 z-10 w-full h-60 bg-white overflow-auto border rounded-lg shadow-lg"
            v-if="input"
          >
            <div
              class="relative transition-all transform origin-top-left h-max"
            >
              <div
                v-if="displayedAmbassadors.length"
                class="w-full absolute bg-white divide-y divide-gray-100 shadow-sm outline-none"
                aria-labelledby="dropdown-menu-button"
                id="dropdown-menu"
                role="menu"
              >
                <div
                  class="py-1 h-full"
                  v-for="(ambassador, index) of displayedAmbassadors"
                  :key="`displayed-ambassador-${index}`"
                >
                  <a
                    href="javascript:void(0)"
                    class="hover:bg-blue hover:text-white flex justify-between w-full px-4 py-2 text-sm leading-5 text-left"
                    role="menuitem"
                    @click="addAmbassador(ambassador)"
                  >
                    {{ ambassador.firstName }} {{ ambassador.lastName }}
                  </a>
                </div>
              </div>
              <div
                v-else
                class="w-full absolute bg-white"
                aria-labelledby="dropdown-menu-button"
                id="dropdown-menu"
                role="menu"
              >
                <div class="py-1 h-full">
                  <p
                    class="text-grey-500 w-full px-4 py-2 text-sm leading-5 text-left"
                    role="menuitem"
                  >
                    No results matched your search.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="my-6">
            <p class="text-grey-500 mb-2 text-sm">Your Message:</p>
            <common-textarea v-model="message" />
          </div>
          <div class="w-full flex justify-end">
            <common-button
              :disabled="
                !message || !Object.keys(ambassadorsToMessage).length || sending
              "
              :loading="sending"
              width="w-40"
              @click="sendMessage"
              >Send</common-button
            >
          </div>
        </div>
      </div>
    </div>
  </common-modal>
</template>

<script>
  import CommonModal from "../Modal.vue";
  import APIService from "@/services/api/index";

  export default {
    name: "send-message-modal",
    props: ["ambassadors", "selectedAmbassadors"],
    components: {
      CommonModal,
    },
    data() {
      return {
        input: "",
        message: "",
        ambassadorsToMessage: {},
        displayedAmbassadors: this.ambassadors,
        sending: false,
      };
    },
    methods: {
      updateMessage(updatedMessage) {
        this.message = updatedMessage;
      },
      addAmbassador(amb) {
        if (!this.ambassadorsToMessage[amb.id]) {
          this.ambassadorsToMessage[amb.id] = amb;
        }

        this.input = "";
      },
      removeAmbassador(ambassador) {
        this.$delete(this.ambassadorsToMessage, ambassador.id);
      },
      ambassadorDropdown(index) {
        return this.profileDropdownIndex === index ? "block" : "hidden";
      },
      targetInput(e) {
        if (e.target.lastChild) e.target.lastChild.focus();
      },
      filterAmbassadors() {
        const results = [];
        const query = this.input.toLowerCase();

        for (let ambassador of this.ambassadors) {
          const ambFullName = `${ambassador.firstName} ${ambassador.lastName}`.toLowerCase();

          if (
            ambassador.firstName.toLowerCase().includes(query) ||
            ambassador.lastName.toLowerCase().includes(query) ||
            ambFullName.includes(query)
          ) {
            results.push(ambassador);
          }
        }

        return results;
      },
      searchAmbassadors() {
        const searchResults = this.filterAmbassadors();

        if (this.input.length) {
          if (searchResults.length > 0) {
            return (this.displayedAmbassadors = searchResults);
          } else {
            return (this.displayedAmbassadors = []);
          }
        }

        this.displayedAmbassadors = this.ambassadors;
      },
      sendMessage: async function () {
        if (!this.message.length)
          return this.$toasted.show(
            `You may not send an empty message.`,
            this.$toastedFailure
          );
        this.sending = true;

        let recipientIDs = Object.keys(this.ambassadorsToMessage);
        let composedMessage = this.message;
        composedMessage = composedMessage.replace("<", "");
        composedMessage = composedMessage.replace(">", "");

        const data = {
          recipientIDs,
          composedMessage,
        };

        const response = await APIService.chat.newBulkMessage(data);
        if (response.status === 200) {
          this.$toasted.show(
            `A message has been sent to ${recipientIDs.length} ambassador(s).`,
            this.$toastedSuccess
          );
          this.$emit("close");
        } else {
          this.$toasted.show(
            `Uh oh! There was an issue.`,
            this.$toastedFailure
          );
          this.sending = false;
        }
      },
    },
    created() {
      for (let ambassador of this.ambassadors) {
        if (this.selectedAmbassadors[ambassador.id])
          this.ambassadorsToMessage[ambassador.id] = ambassador;
      }
    },
  };
</script>
